import React, { useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import GroupIcon from '@material-ui/icons/Group';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewChatModal from "../NewChatModal";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
// import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ChatList from "../ChatList";
import PatchNotesModal from "../PatchNotesModal";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import UserSelectDashboard from "../UserSelectDashboard";
import TicketsSearchedList from "../TicketsSearchedList";
import TagSelectDashboard from "../TagSelectDashboard";
import ResquestNotificationPermissionModal from "../ResquestNotificationPermissionModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
    right: "0",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
}));

const TicketsManager = () => {
  const classes = useStyles();

  const [searchParam, setSearchParam] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [closedTabOpen, setClosedTabOpen] = useState("contacts");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState("");
  const [mili, setMili] = useState(new Date().getMilliseconds());
  const { user } = useContext(AuthContext);

  const [openCount, setOpenCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [userShowAll, setUserShowAll] = useState(null);

  const [notes, setNotes] = useState(false);
  const [pathNotesModalOpen, setPathNotesModalOpen] = useState(false);
  const [requestPermissionModal, setRequestNotificationPermissionModal] = useState(false);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "GERENTE") {
      setShowAllTickets(true);
    }

    if ('Notification' in window) {
      if (Notification.permission === "default") {
        setRequestNotificationPermissionModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchNotes = async () => {
        try {
          const { data } = await api.get("/notes");
          if (data.count > 0)
            setNotes(true);
        } catch (err) {
          toastError(err);
        }
      };
      fetchNotes();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, []);

  useEffect(() => {
    const path = sessionStorage.getItem("path_notes");
    if (!path && notes) {
      setTimeout(()=>{
        setPathNotesModalOpen(true)
      }, 500)
      sessionStorage.setItem("path_notes", true);
    }
  }, [notes]);

  useEffect(() => {
    if (tab === "search") {
      // searchInputRef.current.focus();
    }
  }, [tab]);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");

        const ha = data.find(s => s.key === 'userShowAll')
        if (ha?.value)
          setUserShowAll(ha.value)
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  let searchTimeout;
  let searchTimeoutC;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    // if (searchedTerm === "") {
    //   setSearchParam(searchedTerm);
    //   setTab("open");
    //   return;
    // }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleSearchContact = (e) => {
    const searchedContact = e.target.value.toLowerCase();

    clearTimeout(searchTimeoutC);

    searchTimeoutC = setTimeout(() => {
      setSearchContact(searchedContact);
      setMili(new Date().getMilliseconds())
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const handleChangeClosedTabOpen = (e, newValue) => {
    setClosedTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const applyPanelStyleClosed = (status) => {
    if (closedTabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(e) => setNewTicketModalOpen(false)}
      />
      <NewChatModal
        modalOpen={newChatModalOpen}
        onClose={(e) => setNewChatModalOpen(false)}
      />
      <PatchNotesModal
        modalOpen={pathNotesModalOpen}
        onClose={(e) => setPathNotesModalOpen(false)}
      />

      <ResquestNotificationPermissionModal
        modalOpen={requestPermissionModal}
        onClose={e=>setRequestNotificationPermissionModal(false)}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"closed"}
            icon={<CheckBoxIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"chat"}
            icon={<GroupIcon />}
            label={"Chat interno"}
            classes={{ root: classes.tab }}
          />
          <Tab
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.serachInputWrapper}>
            {/*<SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.searchInput}
              inputRef={searchInputRef}
              placeholder={i18n.t("tickets.search.placeholder")}
              type="search"
              onChange={handleSearch}
            />*/}

            <div className={classes.root}>
              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <Typography className={classes.heading}>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                          id="searchParam"
                          fullWidth
                          label="Mensagem"
                          variant="outlined"
                          onChange={handleSearch}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                          id="searchContact"
                          fullWidth
                          label="Contato"
                          variant="outlined"
                          onChange={handleSearchContact}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TicketsQueueSelect
                          width="100%"
                          style={{ marginLeft: 6 }}
                          selectedQueueIds={selectedQueueIds}
                          userQueues={user?.queues}
                          onChange={(values) => {
                            setSelectedQueueIds(values)
                            setMili(new Date().getMilliseconds())
                          }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <UserSelectDashboard
                          selectedUserIds={selectedUserIds}
                          style={{marginTop: 0}}
                          queueId={0}
                          all={true}
                          disabled={user.profile.toUpperCase() === "USER"}
                          onChange={(values) => {
                            setSelectedUserIds(values)
                            setMili(new Date().getMilliseconds())
                          }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TagSelectDashboard
                          selectedTagIds={selectedTagIds}
                          onChange={(values) => {
                            setSelectedTagIds(values);
                            setMili(new Date().getMilliseconds())
                          }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        ) : tab === "chat" ? (
            /* CHAT INTERNO */
            <>
              <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setNewChatModalOpen(true)}
              >
                {i18n.t("ticketsManager.buttons.newTicket")}
              </Button>
            </>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setNewTicketModalOpen(true)}
	      disabled={user.profile !== "admin"}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>
            { user.profile.toUpperCase() === "ADMIN" || user.profile.toUpperCase() === "GERENTE" || userShowAll === "enabled" ? (
                <FormControlLabel
                    label={i18n.t("tickets.buttons.showAll")}
                    labelPlacement="start"
                    control={
                      <Switch
                          size="small"
                          checked={showAllTickets}
                          onChange={() =>
                              setShowAllTickets((prevState) => !prevState)
                          }
                          name="showAllTickets"
                          color="primary"
                      />
                    }
                />
            ) : null }
          </>
        )}

        { tab !== "search" ? (
            <TicketsQueueSelect
                style={{ marginLeft: 6 }}
                selectedQueueIds={selectedQueueIds}
                userQueues={user?.queues}
                onChange={(values) => setSelectedQueueIds(values)}
            />
        ) : null}
      </Paper>
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge overlap="rectangular"
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            style={{minWidth: 50}}
            value={"open"}
          />
          <Tab
            label={
              <Badge overlap="rectangular"
                className={classes.badge}
                badgeContent={groupCount}
                color="primary"
              >
                Grupos
              </Badge>
            }
            style={{minWidth: 50}}
            value={"group"}
          />
          <Tab
            label={
              <Badge overlap="rectangular"
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            style={{minWidth: 50}}
            value={"pending"}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="group"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setGroupCount(val)}
            style={applyPanelStyle("group")}
          />
          <TicketsList
            status="pending"
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
          />
        </Paper>
      </TabPanel>

      <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <Tabs
            value={closedTabOpen}
            onChange={handleChangeClosedTabOpen}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
        >
          <Tab
              label={
                <Badge overlap="rectangular"
                       className={classes.badge}
                       // badgeContent={openCount}
                       color="primary"
                >
                  Contatos
                </Badge>
              }
              value={"contacts"}
          />
          <Tab
              label={
                <Badge overlap="rectangular"
                       className={classes.badge}
                       // badgeContent={groupCount}
                       color="primary"
                >
                  Grupos
                </Badge>
              }
              value={"groups"}
          />
        </Tabs>
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
              status="closed"
              showAll={true}
              selectedQueueIds={selectedQueueIds}
              style={applyPanelStyleClosed("contacts")}
          />
          <TicketsList
              status="closedGroup"
              showAll={true}
              selectedQueueIds={selectedQueueIds}
              isGroup={true}
              style={applyPanelStyleClosed("groups")}
          />
        </Paper>
      </TabPanel>
      <TabPanel value={tab} name="chat" className={classes.ticketsWrapper}>
        <ChatList
          status="closed"
          showAll={true}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TicketsSearchedList
          searchParam={searchParam}
          searchContact={searchContact}
          key={mili}
          showAll={true}
          selectedQueueIds={selectedQueueIds}
          selectedUserIds={selectedUserIds}
          selectedTagIds={selectedTagIds}
        />
      </TabPanel>
    </Paper>
  );
};

export default TicketsManager;
